import { useEffect, useState } from 'react';

import { isPublic } from './Security';


const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
    return children;
};

export default WithAuth;
