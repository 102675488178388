import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import WithAuth from '../components/Auth/WithAuth';
import '../styles/globals.css';

function NapsesApp({ Component, pageProps, router }) {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const handleStart = () => setLoading(true);
        const handleComplete = () => setLoading(false);

        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleComplete);
        };
    }, [router.events]);
    return (
        <SessionProvider session={pageProps.session}>
            <Head>
                <title>ClimateXCapital</title>
            </Head>
            <WithAuth router={router}>
                <NextNProgress
                    color="#00CC7F"
                    height={5}
                    
                />
                <Component
                    {...pageProps}
                    pageLoading={loading}
                />
            </WithAuth>
        </SessionProvider>
    );
}

export default NapsesApp;
